.invalid {
  color: #f00;
  font-size: 12px;
}

.invalid {
  color: red;
}

.min-text {
  font-size: 12px;
}

.placeholder-text {
  color: #E5E7E9;
  font-size: 0.875rem;
  font-family: inherit;
}

.date-picker {
  width: 100%;
  border-radius: 12px;
  border: #aaa 1px solid;
}

.date-header {
  font-family: inherit;
  font-weight: 600;
  line-height: 1.5;
  color: #32325d;
}

.date-header:hover {
  cursor: pointer;
}

.date-text-start::before {
  content: "Enter starting time";
  color: #9d9d9d;
  position: absolute;
  background: #fff;
}

.order-total {
  font-size: 18px;
  font-weight: bold;
}

.order-subtotal {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 2px;
  line-height: 1;
}

.small-text {
  font-size: 14px;
}

.parent {
  width: 100px;
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.block-ellipsis {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  height: 30px;
  margin: 0 auto;
  font-size: 14px;
  line-height: 1;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
