.image{
    position: relative;
    width: 20rem;
    

}

.fet_img{
    display: block;
    width: 100%;
    border-radius: 2rem;
}

.img_overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);
    color: white;
    display:inline;
    justify-content: end;
    opacity: 0;
    transition: 0.9s;
    border-radius: 2rem;
    font-size:5px;
}

.img_overlay:hover{
    opacity: 1;
}








