.main-container {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.form-login-container {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow: hidden;
}

.login-form {
  padding: 120px;
  margin-top: 3%;
  width: 50%;
  animation: slideInTrans 0.4s ease-in-out;
}

.login-form .logo {
  width: 30%;
  height: auto;
}

.login-form h1 {
  font-weight: bold;
  color: rgb(78, 77, 204);
  line-height: 1;
  margin-bottom: 20%;
  font-size: 2.2em;
}

.login-form .email-field,
.login-form .password-field {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.8rem;
  width: 100%;
  height: 45px;
  margin-top: 0.1rem;
  border: 2px rgb(208, 208, 208) solid;
  border-radius: 12px;
}

.login-form .email-field-error,
.login-form .password-field-error {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.8rem;
  width: 100%;
  height: 45px;
  margin-top: 0.1rem;
  border: 2px rgb(255, 0, 0) solid;
  border-radius: 12px;
}

.login-form a {
  color: rgb(97, 91, 201);
  font-size: 0.9rem;
}

.login-form .password-field span,
.login-form .password-field-error span {
  cursor: pointer;
}

.login-form .password-field span img,
.login-form .password-field-error img {
  width: 20px;
  height: 20px;
}

.login-form .checkbox-section {
  margin-top: 0.7rem;
  margin-left: 0.9rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
}

.login-form .submit-btn {
  width: 95%;
  margin-top: 1.1rem;
  height: 45px;
}

.side-image {
  width: 50%;
  height: 100%;
}

.side-image .image {
  width: 100%;
  height: 100%;
  border-radius: 25px 0 0 25px;
  background-size: cover;
  background-position: center center;
  background-image: url("../img/hero-image-1.jpg");
}

.side-image .image-2 {
  width: 100%;
  height: 100%;
  border-radius: 25px 0 0 25px;
  background-size: cover;
  background-image: url("../img/theme2/Forgot password.jpg");
}

.spinner-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.spinner {
  border-radius: 50%;
  border: 5px #d4d2d2 solid;
  border-top: 5px #fff solid;
  width: 30px;
  height: 30px;
  animation: spinner 0.8s linear infinite;
}

.inputRounded .MuiOutlinedInput-root {
  border-radius: 12px;
  font-size: 15px;
  height: 51px;
}

@media (max-width: 1000px) {
  .form-login-container {
    justify-content: center;
    align-items: center;
  }

  .side-image {
    display: none;
  }

  .login-form {
    width: 100%;
    margin-left: 0;
  }
}

@media (max-width: 600px) {
  .login-form {
    width: 100%;
    padding: 24px;
  }
}

@keyframes slideInTrans {
  from {
    transform: translateX(-20%);
    opacity: 0;
  }

  to {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes spinner {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
