/*!

=========================================================
* Argon Dashboard PRO React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Bootstrap RTL
@import "bootstrap-rtl/bootstrap-rtl";

// Bootstrap functions
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";

// Argon functions
@import "custom/functions";
@import "custom/variables";
@import "~bootstrap/scss/variables";

// Argon mixins
@import "custom/mixins";

// Bootstrap components
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/print";

// Argon components
@import "custom/components";
@import "custom/utilities";
@import "custom/vendors";

@import "custom/ckEditor5";
@import "custom/text";
@import "custom/conference-timeline";

@import "custom/text";
@import "custom/featured_location_image";
@import "custom/booking-container";
@import "custom/booking-timeline";
@import "custom/input-mui-styles";
@import "custom/nav-item";
@import "custom/phone-input-container";
@import "custom/document";
@import "custom/auto-complete";
@import "custom/comment-text-field";
@import "custom/comment-card";

// React differences
@import "react/react-differences";

.preloader {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  width: 48px;
  height: 48px;
  display: flex;
  transform-origin: 50% 125%;
  animation: mov-y 1s infinite linear;
}
.loader:before,
.loader:after {
  content: "";
  flex: 1;
  background: $primary;
  transform-origin: 0% 100%;
  animation: rtr-x 1s infinite linear;
}
.loader:before {
  transform-origin: 100% 100%;
  animation-name: rtr-rx;
}

@keyframes mov-y {
  0%,
  25% {
    transform: translateY(0) scaleY(1);
  }
  49% {
    transform: translateY(-75%) scaleY(1);
  }
  50% {
    transform: translateY(-75%) scaleY(-1);
  }
  75%,
  100% {
    transform: translateY(-150%) scaleY(-1);
  }
}
@keyframes rtr-x {
  25%,
  75% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(90deg);
  }
}
@keyframes rtr-rx {
  25%,
  75% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-90deg);
  }
}

.menu-items {
  overflow: auto;
}

.vertical-line {
  box-sizing: border-box;
  margin-top: 6px;
  width: 1px;
  height: 80%;
  border-style: solid;
  margin-left: 12px;
  border-color: #e5e7eb;
}

.activity-section {
  overflow: auto;
  max-height: 900px;
}

.activity-card {
  max-height: 890px;
}

.outline {
  border: 1px #000000 solid;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  padding-top: 2px;
  width: 100px;
  height: 25px;
}


.attribute-card {
  overflow: hidden;
  height: 0;
  transition: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}