
.booking-time-container {
    display: flex;
    padding: 1.2rem;
    width: 100%;
    overflow-x: auto;
    position: relative;
}

.booking-room-sidebar {
    position: absolute ;
    background-color: #ffffff;
}

.booking-room-sidebar table,
.room-container table {
    border-collapse: collapse;
    width: 100px;
}

.booking-room-sidebar table tr,
.room-container table tr  {
    border: 1px #eee solid;
}

.booking-room-sidebar table th, 
.room-container table th {
    text-align: center;    
}

.booking-room-sidebar table td,
.room-container table td {
    height: 50px;
    text-align: center;
}









