.image-container {
  position: relative;
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #dee2e6;
  border-radius: 10px;
}


.image-container-alt {
  position: relative;
  width: 200px;
  height: 200px;
  background-color: #444;
}

.overlay {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.4);
  overflow: hidden;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 0.5s ease;

 
}

.overlay button {
  position: absolute;
  top: 0.95rem;
  right: 1rem;
}

.overlay h1 {
  color: #fff;
}

.overlay-alt {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.4);
  overflow: hidden;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 0.5s ease;
}

.image {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.image-container:hover .overlay {
  opacity: 1;
}

.image-container:hover .overlay-alt {
  opacity: 1;
}

@media (max-width: 600px) {
  .image-container {
    flex-wrap: wrap;
  }
}

.order-image {
  width: 100%;
  height: auto;
  max-height: 100px;
  object-fit: cover;
  border-radius: 12px;
}
