.form-group .css-oxyvd1-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  border: none;
  border-color: #dee2e6;
  font-size: 0.875rem;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8898aa;
}

.css-oxyvd1-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root
  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: #dee2e6;
}
