
:root {
      --timeline-item-bg: #2096F3;
      --timeline-item-text: white;
      --timeline-sidebar-border: #F0F2F5;
}


.timeline-item {
    background-color: var(--timeline-item-bg);
    border-radius: 12px;
    font-size: 12px;
    padding: 12px;
    color: var(--timeline-item-text);
}

.timeline-sidebar {
    width: 200px;
    padding: 7px;
    border-bottom: var(--timeline-sidebar-border) 1px solid;
}

