.item-nav-active {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75px;
    border-radius: 8px;
    padding: 8px 2px;
    border: 1px #000 solid;
    background-color: #000;
}

.item-nav-active .title {
    color: #fff;
    font-weight: bold;
    font-size: 11px;
    text-align: center;
}

.item-nav-inactive {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75px;
    border-radius: 8px;
    padding: 8px 2px;
    border: 1px #000 solid;
    background-color: transparent;
}

.item-nav-inactive .title {
    color: #000;
    font-weight: bold;
    font-size: 11px;
    text-align: center;
}

.item-nav-active:hover, 
.item-nav-inactive:hover
{
 cursor: pointer;
}
